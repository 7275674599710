import { MatConfirmDialogComponent } from './mat-confirm-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }


  /**
   *
   * open confirm.
   * @param {*} msg Prompt box message.
   * @returns
   * @memberof DialogService
   */
  openConfirmDialog(msg, defaultBtn = ['yes', 'no'], defaultTitle = '') {
    return this.dialog.open(MatConfirmDialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      position: {},
      data: {
        message: msg,
        btns: defaultBtn,
        title: defaultTitle
      }
    });
  }
}
