<div>
  <div class="popup-title">
    <div class="popup-title-content">{{data.title}}</div>
    <i (click)="closeDialog()" class="popup-close-icon mdui-icon material-icons">clear</i>
  </div>
  <div class="popup-content">
    <div class="row" style="flex-wrap: nowrap;">
      <div class="col-auto">
        <p >{{data.message}}</p>
      </div>
    </div>
  </div>
  <div class="popup-footer">
    <button class="confirm" mat-flat-button *ngFor="let btn of data.btns" [mat-dialog-close]="btn">{{btn}}</button>
    <!-- <button class="confirm" mat-flat-button id="yes-button" [mat-dialog-close]="'bbb'">EXCEL</button> -->
    <!-- <button class="confirm" mat-flat-button id="tb-button" [mat-dialog-close]="'ccc'">TABLE</button> -->
  </div>
</div>
